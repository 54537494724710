import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    IconButton,
    CardContent,
    MenuItem,
    Menu, Tooltip, Dialog,
    TextField, Button, DialogTitle, DialogContent, DialogActions, Paper, Box, Link, Typography
} from '@material-ui/core';
import { MoreVert, Edit } from '@material-ui/icons';
import moment from 'moment';
import api from "../../../../services/api";
import { toast } from 'react-toastify';
import {withRouter} from "react-router-dom";
import {DialogQuestione} from "../../../../components";
import { truncateText } from '../../../../utils/truncateText';
import {useTheme, withStyles} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useStyles from "../../../../style/style";
import TooltipQuestione from "../../../../components/TooltipQuestione";
import clsx from "clsx";
import DeleteIcon from "@material-ui/icons/Delete";

const useStylesLocal = makeStyles(() => ({
  root: {
    marginBottom: 8,
  },
    head: {
        paddingBottom: 0,
        paddingTop: 10
    },
    chip:{
      backgroundColor: '#e57373',
      color: '#ffebee',
    },
  spacer: {
    flexGrow: 1
  },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: 2,
        flex: 1,
        fontWeight: 'bold',
        color: '#ffffff'
    },
    fieldsDialog: {
      marginTop: 20
    }
}));

const QuestionsBankCard = props => {
  const { className, history, refresh, setRefresh, questionBank, setTabValue, ...rest } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const classes = useStylesLocal();
  const classesGeneral = useStyles();

    const [openDeleteBank, setOpenDeleteBank] = React.useState(false);

  const onEdit = () => {
      history.push('/question-bank/'+questionBank.id);
  }


    async function onDelete() {
        try {
            let url = 'questions-bank/'+questionBank.id;
            const response = await api.delete(url);
            if (response.status === 202) {
                if(response.data.message){
                    toast.error(response.data.message);
                }
            } else {
                toast.success(response.data.message);
                setRefresh(refresh+1);
            }

        } catch (error) {

        }
        setOpenDeleteBank(false);
    }

    const onClickOpenDialogBank = () => {
        setOpenDeleteBank(true);
    }

    const onClickCloseDialogBank = () => {
        setOpenDeleteBank(false);
    }

  return (
    <Card
      {...rest}
      className={classes.root}>
        <Paper className={classesGeneral.paperTitle}>
            <Box display="flex">
                <Box display="flex" sx={{ flexGrow: 1 }} justifyContent="flex-start">
                    <div className={classesGeneral.paperTitleText}>
                        {questionBank.id < 10 ? '00000' + questionBank.id :
                            questionBank.id < 100 ? '0000' + questionBank.id :
                                questionBank.id < 1000 ? '000' + questionBank.id :
                                    questionBank.id < 10000 ? '00' + questionBank.id :
                                        questionBank.id < 100000 ? '0' + questionBank.id :
                                            questionBank.id}
                    </div>
                    <div className={classesGeneral.paperTitleTextBold} style={{marginLeft: '15px'}}>
                        {truncateText(questionBank.description, 20, isSmallScreen)}
                    </div>
                </Box>
                <Box display="flex" justifyContent="flex-end">
                    <Tooltip title="Editar Banco">
                        <IconButton
                            aria-label="copy"
                            onClick={onEdit}
                            size="small">
                            <Edit />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Deletar Banco">
                        <IconButton
                            aria-label="copy"
                            onClick={onClickOpenDialogBank}
                            size="small" style={{marginLeft:'10px'}}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>

                    <DialogQuestione handleClose={onClickCloseDialogBank}
                                     open={openDeleteBank}
                                     onClickAgree={onDelete}
                                     onClickDisagree={onClickCloseDialogBank}
                                     mesage={'Deseja excluir o banco '+questionBank.description+' ?'}
                                     title={'Excluir Banco de Questões'}/>

                </Box>
            </Box>
        </Paper>
        <CardContent>
            <Link href={`/question-bank-itens/${questionBank.id}`}>
                Clique aqui para acessar este banco de questões.
            </Link>
            {questionBank.questions && questionBank.questions.length === 0 ?
                <div style={{marginRight: '6px', color: '#795548'}}>
                    {'Este banco não possui questões. Acesse este banco para adicionar questões ou use o ícone '}
                    <i className="fa-solid fa-database" style={{fontSize: '19px'}}></i>
                    {' no card da questão'}
                    <Link href="/questions">
                        {' no menu Questões.'}
                    </Link>
                </div>
                :
                <table style={tableStyle}>
                    <thead>
                    <tr>
                        <th style={headerStyle}>
                            <TooltipQuestione description={'Algumas questões podem estar associadas a mais de um conteúdo. Nesses casos, a soma total das questões por conteúdo pode exceder o número real de questões disponíveis no banco, já que cada questão será contabilizada em todos os conteúdos aos quais está vinculada.'} position={'top'} content={
                                <div>
                                    {questionBank.questions.length === 1 ?
                                        'Conteúdos das questões neste banco (' + questionBank.questions.length + ' questão)'
                                        :
                                        'Conteúdos das questões neste banco (' + questionBank.questions.length + ' questões)'}<i
                                    className="fa-solid fa-circle-info"
                                    style={{marginLeft: '4px', fontSize: '14px'}}></i>
                                </div>
                            }/>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {questionBank.totalQuestionsByContent.map((content, index) => (
                        <tr key={index}>
                            <td style={cellStyle}>{content.total === 1 ? content.description+' ('+content.total+' questão)' :
                                content.description+' ('+content.total+' questões)'}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            }

        </CardContent>
    </Card>
  );
};

// Estilos da tabela
const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
    margin: '20px 0',
    border: '1px solid #b0c4de', // Borda azul clara
};

const headerStyle = {
    padding: '10px',
    backgroundColor: '#e6f2ff', // Azul suave para o cabeçalho
    borderBottom: '2px solid #b0c4de', // Borda azul clara
    textAlign: 'left',
    color: '#004080', // Azul escuro
    fontWeight: 'bold',
};

const cellStyle = {
    padding: '8px',
    borderBottom: '1px solid #b0c4de', // Borda azul clara
    textAlign: 'left',
};


QuestionsBankCard.propTypes = {
    className: PropTypes.string,
    evaluation: PropTypes.object,
    history: PropTypes.object,
    setRefresh: PropTypes.func,
    refresh: PropTypes.number
};

export default withRouter(QuestionsBankCard);
