import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';
import { getLevel } from "../../../../../../../helpers/LevelStudent";
import {Button, Modal} from "react-bootstrap";
import EvaluationQuestionCard
    from "../../../../../../Professor/Evaluation/EvaluationQuestions/components/EvaluationQuestionCard";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import {Typography} from "@material-ui/core";
import api from "../../../../../../../services/api";
import {toast} from "react-toastify";
import moment from "moment";

ChartJS.register(LineElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const LineChart = ({ data, questions, student, content, width = 400, height = 400, highlightCount = 5, maxRecords = 50, filterSelect }) => {

    const [showModal, setShowModal] = useState(false);
    const [pontoSelect, setPontoSelect] = useState(null);
    const [questionSelect, setQuestionSelect] = useState(null);

    async function findAQuestion(id){
        try {
            const response = await api.get('question/show/'+id);

            if (response.status === 200) {
                setQuestionSelect(response.data[0]);

            } else {
                if(response.data.message){
                    toast.error( response.data.message);
                }
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        if(pontoSelect != null){
            const selectedId = pontoSelect.question;

            findAQuestion(selectedId);
        }

    }, [pontoSelect]);

    useEffect(() => {

    }, [data]);

    const colors = [
        'rgba(54, 162, 235, 1)',  // Blue
        'rgba(255, 99, 132, 1)',  // Red
        'rgba(255, 206, 86, 1)',  // Yellow
        'rgba(75, 192, 192, 1)',  // Teal
        'rgba(153, 102, 255, 1)', // Purple
        'rgba(255, 159, 64, 1)',  // Orange
        'rgba(201, 203, 207, 1)', // Gray
        'rgba(255, 205, 86, 1)',  // Light Yellow
        'rgba(54, 162, 135, 1)',  // Greenish Blue
        'rgba(153, 255, 102, 1)', // Light Green
    ];

    // Função para formatar a data para o formato dia/mês/ano
    const formatDate = (dateString) => {
        return moment(dateString).format('DD/MM/YYYY');
    };

    const handleClose = () => {
        setShowModal(false);
        setPontoSelect(null);
        setQuestionSelect(null);
    }

    // Prepara os dados para o gráfico
    const chartData = {
        //labels: data.length ? data[0].history.slice(-maxRecords).map(item => formatDate(item.created_at)) : [],  // Datas
        labels: data.length
            ? data.flatMap(item => item.history.slice(-maxRecords)).map(item => formatDate(item.created_at))
            : [],
        datasets: data.map((item, index) => {
            const historyData = item?.history ? item.history.slice(-maxRecords) : [];

            const actualCount = historyData.length; // Contagem real de dados

            // Define a cor da linha e do ponto baseado no índice e na lista de cores
            const borderColor = colors[index % colors.length];  // Cor cíclica
            const backgroundColor = borderColor;

            return {
                label: item?.object?.description || 'Descrição não disponível',
                data: historyData.map(historyItem => historyItem.elo),  // Usando o valor de `elo`
                fill: false,
                borderColor: borderColor,
                historyData: historyData,
                tension: 0.1,
                pointRadius: historyData.map((_, index) => {
                    const adaptiveSize = historyData[index].adaptive ? 2 : 0;
                    return index >= actualCount - highlightCount ? adaptiveSize : 1;
                }),
                pointBackgroundColor: historyData.map((historyItem, index) => {
                    // Verifica se o atributo 'adaptive' existe e define a cor
                    const adaptiveColor = historyItem.adaptive ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 255, 1)';

                    // Ajusta a cor com base na condição
                    return index >= actualCount - highlightCount ? adaptiveColor : 'rgba(0, 0, 0, 0.5)';
                }),
                backgroundColor: historyData.map((_, index) => {
                    return index >= actualCount - highlightCount ? backgroundColor : backgroundColor;
                }),
                borderWidth: 2, // Define a espessura da linha
            };
        }),
    };

    // Ajustar as labels para mostrar apenas a primeira, a última e duas datas no meio
    const firstDate = chartData.labels[0]; // Primeira data
    const lastDate = chartData.labels[chartData.labels.length - 1]; // Última data
    const totalLabels = chartData.labels.length;

    const adjustedLabels = chartData.labels.map((date, index) => {

        // Exibir rótulos em posições específicas
        if (index === 0 || index === totalLabels - 1 ||
            index === Math.floor(totalLabels / 3) ||
            index === Math.floor(2 * totalLabels / 3)) {

            if (date === 'Data inválida') {
                return index; // Nome a ser retornado para data inválida
            }

            return date; // Retorna a data válida
        }

        return ''; // Deixar os outros valores em branco
    });

    chartData.labels = adjustedLabels; // Atualiza as labels no chartData

    // Configurações do gráfico
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: content,
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    label: function (context) {
                        const dataset = chartData.datasets[context.datasetIndex];
                        const ponto = dataset.historyData[context.dataIndex];
                        console.log('ponto', ponto);


                        return [
                            `${student} ${ponto.correct === 1 ? 'acertou' : 'errou'} a questão de dificuldade ${ponto.difficulty}`,
                            `no conteúdo ${dataset.label}.`,
                            `A habilidade do estudante foi de ${Math.floor(ponto.elo)}.`,
                            `A chance de acerto era de ${Math.floor(ponto.expected_score * 100)}%.`,
                            `O tempo gasto na questão foi de ${displayTime(ponto.time)}.`,
                            `${ponto.suitable === 1 ? 'A questão foi considerada adequada ao nível de habilidade.' :
                                ponto.suitable === 0 ? 'A questão foi considerada inadequada ao nível de habilidade.' :
                                    'A adequação da questão não foi avaliada.'}`
                        ];
                    },
                },
            },
            datalabels: {
                display: false,
            },
        },
        hover: {
            mode: 'nearest',
            intersect: true,
            onHover: function (event, chartElement) {
                const target = event.native ? event.native.target : event.currentTarget;
                if (chartElement.length) {
                    target.style.cursor = 'pointer'; // Altera o cursor para pointer
                } else {
                    target.style.cursor = 'default'; // Volta para o cursor padrão
                }
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Período',
                },
                ticks: {
                    autoSkip: false,  // Disable auto skipping
                    maxRotation: 0,  // Prevent rotation
                    minRotation: 0,  // Prevent rotation
                },

            },
            y: {
                title: {
                    display: true,
                    text: '',
                },
                min: 0,
                max: 1000,
                beginAtZero: true,
                ticks: {
                    stepSize: 200,
                    callback: function(value) {
                        if (value === 0) return '';
                        return value <= 1000 ? getLevel(value) : '';
                    },
                },
            },
        },
        onClick: (event, elements) => {
            if (elements.length > 0) {
                const datasetIndex = elements[0].datasetIndex;
                const index = elements[0].index;

                const dataset = chartData.datasets[datasetIndex];
                const ponto = dataset.historyData[index];


                setPontoSelect(ponto);

                setShowModal(true);
            }
        },
    };

    const displayTime = (time) => {
        const parts = [];

        // Adiciona apenas os valores maiores que zero
        if (time.days > 0) parts.push(`${time.days} dias`);
        if (time.hours > 0) parts.push(`${time.hours} horas`);
        if (time.minutes > 0) parts.push(`${time.minutes} minutos`);
        if (time.seconds > 0) parts.push(`${time.seconds} segundos`);

        // Junta as partes com vírgulas e "e" antes do último item
        return parts.length > 1
            ? parts.slice(0, -1).join(', ') + ' e ' + parts[parts.length - 1]
            : parts[0] || 'a'; // Retorna 'a' se não houver tempo
    };

    return (
        <div style={{ width: `${width}%`, height: `${height}%` }}>
            <Line data={chartData} options={options} />

            <Dialog
                open={showModal}
                onClose={handleClose}
                fullWidth
                maxWidth="lg"
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <DialogTitle id="modal-title">Informações da tentativa de {student}</DialogTitle>
                <DialogContent dividers>
                    {pontoSelect ? (
                        <>
                            <div style={{ padding: '5px', marginBottom: '10px' }}>
                                {student} <strong>{pontoSelect.correct === 1 ? 'acertou' : 'errou'}</strong> esta questão.
                            </div>

                            {questionSelect && (
                                <EvaluationQuestionCard
                                    question={questionSelect}
                                    hasApplication={1}
                                />
                            )}
                        </>
                    ) : (
                        <Typography variant="body2">Nenhuma informação disponível</Typography>
                    )}
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default LineChart;
