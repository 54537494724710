import React, {useEffect} from 'react';
import { Modal, Button } from 'react-bootstrap';

const QuestionBankDialog = ({ show, handleClose, banks, onSelectBank }) => {

    useEffect(() => {

    }, [banks]);

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Selecione o Banco de Questões</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{
                    padding: '10px',
                    backgroundColor: '#f0f0f0',
                    borderRadius: '4px',
                    color: '#555',
                    fontSize: '14px',
                    lineHeight: '1.6',
                    marginBottom: '16px',
                    fontStyle: 'italic'
                }}>{'Abaixo estão os bancos de questões que você cadastrou no menu ' +
                    '"Banco de Questões". Clique no banco desejado para adicionar a nova questão.'}</div>
                <ul className="list-group">
                    {banks && banks.map((bank, index) => (
                        <li key={index} className="list-group-item">
                            <Button
                                variant="link"
                                className="text-left"
                                onClick={() => {
                                    onSelectBank(bank);
                                    handleClose();
                                }}>
                                {bank.questions.length === 0 ?
                                    bank.description
                                    :
                                    bank.questions.length === 1 ?
                                    bank.description + ' ('+bank.questions.length+' questão)'
                                    :
                                    bank.description + ' ('+bank.questions.length+' questões)'}
                            </Button>
                        </li>
                    ))}
                </ul>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Fechar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default QuestionBankDialog;
