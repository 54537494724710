import React, {useEffect, useState} from 'react';
import { Box, Button, Checkbox, FormControlLabel, useMediaQuery } from '@material-ui/core';
import api from "../../services/api";
import CustomSelect from "../CustomSelect";
import AlertMessage from "../Messages/AlertMessage";

const SearchFilterQuestion = ({
                                  enableSearchQuestionBank = false,
                                  onClickSearch,
                                  idBank = 0,
                                  showUnfinishedQuestions = false
                      }) => {
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const [years, setYears] = useState(null);
    const [yearSelect, setYearSelect] = useState(null);
    const [courseSelect, setCourseSelect] = useState(null);
    const [questionBankSelect, setQuestionBankSelect] = useState(idBank);
    const [questionBankDescriptionSelect, setQuestionBankDescriptionSelect] = useState('');
    const [objectSelect, setObjectSelect] = useState(null);
    const [objectDecompositionSelect, setObjectDecompositionSelect] = useState(null);
    const [filterByUser, setFilterByUser] = useState(false);
    const [unfinishedQuestions, setUnfinishedQuestions] = useState(false);
    const [typeEvaluationSelect, setTypeEvaluationSelect] = useState(null);
    const [enableFilter, setEnableFilter] = React.useState(false);
    const [questionsPerPageSelect, setQuestionsPerPageSelect] = useState(10);
    const [typeEvaluation, setTypeEvaluation] = useState(null);
    const [courses, setCourses] = useState([]);
    const [objects, setObjects] = useState([]);
    const [objectsDecomposition, setObjectsDecomposition] = useState([]);
    const [questionsBanks, setQuestionsBanks] = useState(null);

    const optionsQuestionsPerPagge = [
        { id: 5, description: '5' },
        { id: 10, description: '10' },
        { id: 20, description: '20' },
        { id: 30, description: '30' },
        { id: 40, description: '40' },
        { id: 50, description: '50' },
        { id: 100, description: '100' },
    ];

    const handleChangeQuestionsPerPage = (e) => {
        setQuestionsPerPageSelect(e.target.value);
    };

    const onToggleFilterByUser = (event) => {
        setFilterByUser(event.target.checked);
        if(event.target.checked){
            setUnfinishedQuestions(false);
        }
    };

    const onToggleUnfinishedQuestions = (event) => {
        setUnfinishedQuestions(event.target.checked);
        if(event.target.checked){
            setFilterByUser(false);
        }
    };

    const onClean = () =>{
        setCourseSelect(0);
        setObjectSelect(0);
        setObjectDecompositionSelect(0);
        setTypeEvaluationSelect(0);
        setYearSelect(0);
        setUnfinishedQuestions(false);
        setFilterByUser(false);
        setEnableFilter(false);
    }

    const onChangeYear = (e) =>{
        setYearSelect(e.target.value);
    }

    const handleTypeEvaluation = (event) =>{
        setTypeEvaluationSelect(event.target.value);
    }

    const handleObjectDecomposition = (event) =>{
        setObjectDecompositionSelect(event.target.value);
    }

    const onChangeObject = (e) =>{
        setObjectSelect(e.target.value);
    }

    const onChangeCourse = (e) =>{
        setCourseSelect(e.target.value);
        setObjectSelect(null);
        if(e.target.value > 0 ){
            setEnableFilter(true);
        }
    }

    const onChangeQuestionBank = (e) =>{
        const value = e.target.value;
        setQuestionBankSelect(value);
        onModifyDescriptionQuestinBank(value);
    }

    const onModifyDescriptionQuestinBank = (id) =>{
        if(questionsBanks && Number(id) > 0) {
            const foundBank = questionsBanks.find(bank => bank.id === Number(id));
            setQuestionBankDescriptionSelect(foundBank.description);
        } else {
            setQuestionBankDescriptionSelect('');
        }
    }

    async function loadCourses(){
        try {
            let url = 'all/courses-user';
            if(questionBankSelect > 0){
                url += '?fk_bank_id='+questionBankSelect;
            }

            const response = await api.get(url);
            setCourses([...response.data]);

        } catch (error) {

        }
    }

    async function loadQuestionsBank(page){
        try {
            let url = 'questions-bank?page='+page;

            const response = await api.get(url);
            if(response.status == 200) {
                setQuestionsBanks(response.data.data);
            } else {
                setQuestionsBanks([]);
            }
        } catch (error) {

        }
    }

    async function loadObjects(){
        try {
            let url = 'all/objects?fk_course_id='+courseSelect;
            if(questionBankSelect > 0){
                url += '&fk_bank_id='+questionBankSelect;
            }

            const response = await api.get(url);

            setObjects([...response.data]);
            console.log(response);

        } catch (error) {

        }
    }

    async function loadObjectsDecomposition(){
        try {
            let url = 'all/objects-decomposition?fk_knowledge_objects_id='+objectSelect;
            if(questionBankSelect > 0){
                url += '&fk_bank_id='+questionBankSelect;
            }

            const response = await api.get(url);

            setObjectsDecomposition([...response.data]);

        } catch (error) {

        }
    }

    async function loadTypeOfEvaluation(){
        try {
            const fk_course_id = courseSelect;
            const data = {
                fk_course_id
            }
            const response = await api.get('all/type-of-evaluations-by-area?fk_course_id='+courseSelect);

            setTypeEvaluation([...response.data]);

        } catch (error) {

        }
    }

    async function loadYear(){
        try {
            const fk_course_id = courseSelect;
            const data = {
                fk_course_id
            }
            const response = await api.get('all/year-by-area?fk_course_id='+courseSelect);

            setYears(response.data);

        } catch (error) {

        }
    }

    useEffect(() => {
        let course = 0, object = 0, objectsDecomposition = 0, typeEvaluation = 0,
            year = 0, questionsPerPage = 0, questionBank = 0, showUnfinishedQuestions = 0, showOnlyMyQuestions = 0;
        if(localStorage.getItem('@Questione-search-course') != null){
            course = localStorage.getItem('@Questione-search-course');
        }
        if(localStorage.getItem('@Questione-search-object') !== 0){
            object = localStorage.getItem('@Questione-search-object');
        }
        if(localStorage.getItem('@Questione-search-object-decomposition') !== 0){
            objectsDecomposition = localStorage.getItem('@Questione-search-object-decomposition');
        }
        if(localStorage.getItem('@Questione-search-type-evaluation') !== ''){
            typeEvaluation = localStorage.getItem('@Questione-search-type-evaluation');
        }
        if(localStorage.getItem('@Questione-search-year-question') !== ''){
            year = localStorage.getItem('@Questione-search-year-question');
        }
        if(localStorage.getItem('@Questione-total-per-page') != ''){
            questionsPerPage = localStorage.getItem('@Questione-total-per-page');
        }
        /*if(localStorage.getItem('@Questione-question-bank') != ''){
            questionBank = localStorage.getItem('@Questione-question-bank');
            onModifyDescriptionQuestinBank(localStorage.getItem('@Questione-question-bank'));
        }*/
        if(localStorage.getItem('@Questione-show-unfinished-questions') != ''){
            showUnfinishedQuestions = localStorage.getItem('@Questione-show-unfinished-questions');
        }
        if(localStorage.getItem('@Questione-show-only-my-questions') != ''){
            showOnlyMyQuestions = localStorage.getItem('@Questione-show-only-my-questions');
        }
        const existsCourse = courses.some(c => c.id === Number(course));

        if(!existsCourse){
            course = 0;
            object = 0;
            objectsDecomposition = 0;
            typeEvaluation = 0;
            year = 0;
            questionsPerPage = 10;
            //questionBank = 0
        }
        setCourseSelect(course);
        setObjectSelect(object);
        setObjectDecompositionSelect(objectsDecomposition);
        setTypeEvaluationSelect(typeEvaluation);
        setYearSelect(year);
        setQuestionsPerPageSelect(questionsPerPage);
        //setQuestionBankSelect(questionBank);
        if(showUnfinishedQuestions === "true"){
            setUnfinishedQuestions(true);
        }
        /*if(showOnlyMyQuestions === "true"){
            setFilterByUser(true)
        }*/

        onClickSearch(
            questionBank,
            course,
            object,
            objectsDecomposition,
            typeEvaluation,
            year,
            null,
            questionsPerPageSelect,
            unfinishedQuestions);

    }, [courses]);

    useEffect(() => {
        setQuestionBankSelect(idBank);
        loadCourses();
        loadQuestionsBank();

    }, []);

    useEffect(() => {
        loadCourses();
        setCourseSelect(0);
        setObjectSelect(0);
        setObjectDecompositionSelect(0);
        setTypeEvaluationSelect(0);
        setYearSelect(0);
    }, [questionBankSelect]);

    useEffect(() => {
        if(courseSelect > 0) {
            loadObjects();
            loadTypeOfEvaluation();
            loadYear();
            setEnableFilter(true);
        }
    }, [courseSelect]);

    useEffect(() => {
        if(objectSelect > 0) {
            loadObjectsDecomposition();
        }
    }, [objectSelect]);

    const handleSearchClick = () => {
        localStorage.setItem('@Questione-question-bank', questionBankSelect);
        localStorage.setItem('@Questione-search-course', courseSelect);
        localStorage.setItem('@Questione-search-object', objectSelect);
        localStorage.setItem('@Questione-search-object-decomposition', objectDecompositionSelect);
        localStorage.setItem('@Questione-search-type-evaluation', typeEvaluationSelect);
        localStorage.setItem('@Questione-search-year-question', yearSelect);
        localStorage.setItem('@Questione-total-per-page', questionsPerPageSelect);
        localStorage.setItem('@Questione-show-unfinished-questions', unfinishedQuestions);
        localStorage.setItem('@Questione-show-only-my-questions', filterByUser);

        onClickSearch(
            questionBankSelect,
            courseSelect,
            objectSelect,
            objectDecompositionSelect,
            typeEvaluationSelect,
            yearSelect,
            filterByUser,
            questionsPerPageSelect,
            unfinishedQuestions);
    };

    return (
        <div>
            {enableSearchQuestionBank && (
                questionBankSelect > 0 ? (
                    <AlertMessage
                        message={
                            questionBankDescriptionSelect !== ''
                                ? `Você está pesquisando no banco de questões ${questionBankDescriptionSelect}.`
                                : 'Você está pesquisando em um banco de questões específico.'
                        }
                    />
                ) : (
                    <AlertMessage message="Você está pesquisando no banco de questões GERAL." />
                )
            )}

            {enableSearchQuestionBank && (
                <Box display="flex" flexWrap="wrap" flexDirection="row" alignItems="center" justifyContent="flex-start" marginBottom="15px">
                    <Box style={{ flex: '1 1 200px', marginRight: '10px' }}>
                        <CustomSelect
                            text="Banco de Questões"
                            value={questionBankSelect}
                            onChange={onChangeQuestionBank}
                            textOption="Todas os bancos"
                            visibility={true}
                            width="100%"
                            valuesMap={questionsBanks}
                        />
                    </Box>
                </Box>
            )}

            <Box display="flex" flexDirection="column" marginTop="20px">
                <Box display="flex" flexWrap="wrap" flexDirection="row" alignItems="center" justifyContent="flex-start" marginBottom="15px">
                    <Box style={{ flex: '1 1 200px', marginRight: '10px' }}>
                        <CustomSelect
                            text="Área"
                            value={courseSelect}
                            onChange={onChangeCourse}
                            textOption="Todas as áreas"
                            visibility={true}
                            width="100%"
                            valuesMap={courses}
                        />
                    </Box>

                    <Box style={{ flex: '1 1 200px', marginRight: '10px', marginTop: isSmallScreen ? '10px' : '0px' }}>
                        <CustomSelect
                            text="Conteúdo"
                            value={objectSelect}
                            onChange={onChangeObject}
                            disabled={!enableFilter}
                            visibility={true}
                            textOption="Todos os conteúdos"
                            width="100%"
                            valuesMap={objects}
                        />
                    </Box>
                    {objectsDecomposition.length > 0 &&
                        <Box style={{ flex: '1 1 200px', marginRight: '10px', marginTop: isSmallScreen ? '10px' : '0px' }}>
                            <CustomSelect
                                text="Tópico"
                                value={objectDecompositionSelect}
                                onChange={handleObjectDecomposition}
                                disabled={!enableFilter}
                                visibility={true}
                                textOption="Todos"
                                width="100%"
                                valuesMap={objectsDecomposition}
                            />
                        </Box>}

                    <Box style={{ flex: '1 1 200px', marginRight: '10px', marginTop: isSmallScreen ? '10px' : '0px' }}>
                        <CustomSelect
                            text="Banca"
                            value={typeEvaluationSelect}
                            onChange={handleTypeEvaluation}
                            disabled={!enableFilter}
                            visibility={true}
                            textOption="Todos"
                            width="100%"
                            valuesMap={typeEvaluation}
                        />
                    </Box>

                    <Box style={{ flex: '1 1 200px', marginTop: isSmallScreen ? '10px' : '0px' }}>
                        <CustomSelect
                            text="Ano"
                            value={yearSelect}
                            onChange={onChangeYear}
                            disabled={!enableFilter}
                            visibility={true}
                            textOption="Todos"
                            width="100%"
                            valuesMap={years}
                        />
                    </Box>
                </Box>

                <Box display="flex" justifyContent={isSmallScreen ? "center" : "flex-start"} flexDirection={isSmallScreen ? "column" : "row"} style={{ marginTop: '5px' }}>
                    <Box display="flex" style={{ flex: '1 1 30px', marginBottom: '5px' }}>
                        <div className="mb-3" style={{ display: 'flex' }}>
                            <label htmlFor="type-of-evaluation" className="form-label" style={{ marginTop: '6px' }}>
                                {"Total por Página"}
                            </label>
                            <select
                                id="select-total-page"
                                className="form-select"
                                value={questionsPerPageSelect}
                                onChange={handleChangeQuestionsPerPage}
                                aria-describedby="select-total-page"
                                style={{ width: "100px", marginLeft: '10px' }}>
                                <option value={0}>Selecione</option>
                                {optionsQuestionsPerPagge && optionsQuestionsPerPagge.map((type) => (
                                    <option key={type.id || type} value={type.id || type}>
                                        {type.description || type}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </Box>

                    {showUnfinishedQuestions &&
                        <Box display="flex" justifyContent="center" style={{ flex: '1 1 50px' }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={unfinishedQuestions}
                                        onChange={onToggleUnfinishedQuestions}
                                        name="unfinishedQuestions"
                                    />
                                }
                                label={isSmallScreen ? "Questões não finalizadas" : "Mostrar as minhas questões não finalizadas"}
                            />
                        </Box>}

                    <Box display="flex" justifyContent="center" style={{ flex: '1 1 50px' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={filterByUser}
                                    onChange={onToggleFilterByUser}
                                    name="filterByUser"
                                />
                            }
                            label={isSmallScreen ? "Apenas minhas questões" : "Mostrar apenas as minhas questões"}
                        />
                    </Box>
                </Box>

                <Box display="flex" justifyContent={isSmallScreen ? "center" : "center"} style={{ marginTop: '10px' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSearchClick}
                        style={{ height: '35px' }}>
                        Filtrar questões
                    </Button>
                    <div style={{ marginLeft: '10px' }}>
                        <Button variant="contained" onClick={onClean} style={{ height: '35px' }}>
                            {isSmallScreen ? "Limpar" : "Limpar Filtro"}
                        </Button>
                    </div>
                </Box>
            </Box>
        </div>
    );
};

export default SearchFilterQuestion;
