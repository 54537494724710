import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Table,
  TableBody,
  LinearProgress,
  TablePagination, Grid
} from '@material-ui/core';
import api from '../../../../services/api';
import PropTypes from "prop-types";
import QuestionCard from "../../../../components/QuestionCard/QuestionCard";
import {QUESTION_SEARCH_SKILL, searchQuestions, searchQuestionsPage} from "../../../../services/seacrhQuestions";
import useStyles from "../../../../style/style";
import QuestionsBankToolbar from "./components/QuestionsBankToolbar";
import QuestionsBankCard from "../QuestionsBankCard";
import AlertMessage from "../../../../components/Messages/AlertMessage";

const QuestionsBankTable = props => {
  const { className, history } = props;

  const [questionsBank, setQuestionsBank] = useState(null);

  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [total, setTotal] = useState(0);
  const [searchText, setSearchText] = useState(null);
  const [refresh, setRefresh] = React.useState(0);
  const [page, setPage] = useState(0);

  async function loadQuestionsBank(page){
    try {
      let url = 'questions-bank?page='+page;

      if(searchText && searchText != ''){
        url += '&description='+searchText;
      }

      const response = await api.get(url);
      if(response.status == 200) {
        setTotal(response.data.total);
        setRowsPerPage(response.data.per_page);
        setQuestionsBank(response.data.data);
      } else {
        setQuestionsBank([]);
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    loadQuestionsBank(1);
  }, [refresh]);

  const updateSearch = (e) => {
    setSearchText(e.target.value);
  }

  const onClickSearch = (e) => {
    setPage(0);
    loadQuestionsBank(1);
  }

  const onClickCleanSearch = (e) => {
    searchText[0] = {"value" : "T"};
    searchText[0] = {"fk_course_id" : 0};
    searchText[0] = {"fk_object_id" : 0};
    searchText[0] = {"year" : 0};
    searchText[0] = {"keyword" : ''};
    searchText[0] = {"fk_type_of_evaluation_id" : 0};
    onClickSearch();
  }

  const handlePageChange = (event, newPage) => {
    loadQuestionsBank(newPage+1);
    setPage(newPage);
    searchQuestionsPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const onClean = () =>{
    setSearchText('');
    loadQuestionsBank(0);
  }

  return (
      <div className={classes.root}>
        <QuestionsBankToolbar
              searchText={searchText}
              onClickSearch={onClickSearch}
              onClean={onClean}
              onChangeSearch={updateSearch.bind(this)}/>
        <div className={classes.content}>
          <div style={{
            padding: '10px',
            backgroundColor: '#f0f0f0',
            borderRadius: '4px',
            color: '#555',
            fontSize: '14px',
            lineHeight: '1.6',
            marginBottom: '16px',
            fontStyle: 'italic'
          }}>{'A funcionalidade "Bancos de Questões" é uma ferramenta projetada para otimizar o ' +
              'gerenciamento e a organização das suas questões. Com este recurso, você pode acessar ' +
              'facilmente um conjunto personalizado de questões que atendem às suas necessidades ' +
              'específicas. Você pode criar bancos de questões para cada uma de suas disciplinas. ' +
              'Essa personalização não apenas facilita a preparação de avaliações, mas também é ' +
              'essencial para promover avaliações adaptativas. Ao elaborar um simulado adaptativo, ' +
              'você pode especificar qual banco de questões será utilizado, permitindo que o sistema ' +
              'forneça questões ajustadas ao nível de conhecimento do aluno.'}</div>

          {questionsBank == null ?
              <LinearProgress color="secondary"/>
              : questionsBank.length === 0 ?
                  <AlertMessage message="0 bancos encontrados." />
                  :
                  <div>
                    <TablePagination
                        component="div"
                        count={total}
                        onChangePage={handlePageChange}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[rowsPerPage]}/>
                    <Grid
                        container
                        spacing={1}>
                      <Grid
                          item
                          md={12}
                          xs={12}>
                        <Table>
                          <TableBody>
                            {questionsBank.map(bank => (
                                <div style={{marginBottom: '20px'}}>
                                  <QuestionsBankCard
                                      questionBank={bank}
                                      setRefresh={setRefresh}
                                  />
                                </div>
                            ))}
                          </TableBody>
                        </Table>
                      </Grid>
                    </Grid>
                    <TablePagination
                        component="div"
                        count={total}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handleRowsPerPageChange}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[rowsPerPage]}/>

                  </div>}

        </div>
      </div>
  );
};

QuestionsBankTable.propTypes = {
  history: PropTypes.object
};

export default QuestionsBankTable;
