import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {Box, Button, Card, CardContent, IconButton, TextField, useMediaQuery} from '@material-ui/core';
import {withRouter} from "react-router-dom";
import useStyles from "../../../../style/style";
import {CharmHome} from "../../../../icons/Icons";
import BreadcrumbsComponent from "../../../../components/BreadcrumbsComponent/BreadcrumbsComponent";
import api from "../../../../services/api";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import QuestionList from "../../../../components/QuestionList";
import {DialogQuestione} from "../../../../components";
import {toast} from "react-toastify";
import SearchFilterQuestion from "../../../../components/SearchFilterQuestion/SearchFilterQuestion";


const useStylesLocal = makeStyles(theme => ({
  textField: {
    marginLeft: theme.spacing(1),
  },
}));

const QuestionsBankToolbar = props => {
  const { className, onClickSearch, onChangeSearch, searchText, setRefresh, history, handleStatusCallback, setStatus, idBank, ...rest } = props;

  const classes = useStylesLocal();
  const classesGeneral = useStyles();
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const [value, setValue] = useState(1);
  const [bank, setBank] = useState(null);
  const [openAddQuestion, setOpenAddQuestion] = useState(false);
  const [openConfirmAddQuestion, setOpenConfirmAddQuestion] = useState(false);
  const [selectedQuestions, setSelectedQuestions] = useState([]);

  const handleClickOpenAddQuestion = () => {
    setOpenAddQuestion(true);
  };

  async function loadQuestionsBank(){
    try {
      let url = 'questions-bank/show/'+idBank;

      /*if(searchText[0].value){
        url += '&description='+searchText[0].value;
      }*/

      const response = await api.get(url);
      if(response.status == 200) {
        setBank(response.data);
      } else {
        setBank(null);
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    loadQuestionsBank();
  }, []);

  const breadcrumbItems = [
    { href: '/', icon: <CharmHome />, label: 'Início' },
    { href: '/questions-bank', label: 'Bancos de Questões' },
    { label: bank ? 'BQ de '+bank.description : 'BQ'},
  ];

  const onClickRequestUsers = () => {
    history.push('/evaluations-archive');
  }

  const onClickNewBank = e => {
    history.push('/question-bank');
  }

  const handleChange = (event) => {
    handleStatusCallback(1, event.target.value, searchText);
    setValue(event.target.value);
    setStatus(event.target.value);
  };

  const handleCloseAddQuestion = () => {
    setOpenAddQuestion(false);
    if(selectedQuestions.length > 0 ){
      setOpenConfirmAddQuestion(true);
    }
  };

  const handleQuestionsSelected = (questions) => {
    setSelectedQuestions(questions);
    handleCloseOpenConfirmAddQuestion();
  };

  const handleCloseOpenConfirmAddQuestion = () => {
    setOpenConfirmAddQuestion(false);
  };

  async function addQuestion (questionId) {
    try {
      let url = '/questions-bank/add-question/'+idBank;
      const fk_question_id = questionId;
      const data = {
        fk_question_id
      }

      const response = await api.post(url, data);

      if (response.status === 202) {
        if(response.data.message){
          toast.error(response.data.message);
        }
      } else {
        toast.success(response.data.message);
        setRefresh(new Date());
      }
    } catch (error) {

    }

  }

  async function onClickConfirmAddQuestions(){
    if (selectedQuestions.length > 0) {
      // Loop para adicionar as questões na sequência
      for (const questionId of selectedQuestions) {
        try {
          await addQuestion(questionId);
        } catch (error) {
          toast.error(`Erro ao adicionar a questão ${questionId}:`);
          // Você pode querer lidar com o erro de forma diferente dependendo do seu caso de uso
        }
      }
    }
    setSelectedQuestions([]);
    handleCloseOpenConfirmAddQuestion(false);
  }

  const onClickNoConfirmAddQuestions = () =>{
    setSelectedQuestions([]);
    handleCloseOpenConfirmAddQuestion(false);
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}>
      <BreadcrumbsComponent items={breadcrumbItems} />
      <Card >
        <CardContent>
          <Box display="flex">
            <Box display="flex" justifyContent="flex-start">
              <div style={{
                backgroundColor: '#f0f4ff',  // Azul suave
                color: '#004080',            // Azul escuro para o texto
                padding: '10px 20px',        // Espaçamento interno
                borderLeft: '5px solid #004080', // Barra à esquerda para destaque
                fontSize: '1.5em',           // Aumentar o tamanho da fonte
                fontWeight: 'bold',          // Negrito para chamar atenção
                borderRadius: '8px',         // Bordas arredondadas para suavizar o visual
                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', // Sombra leve
                marginBottom: '20px'         // Espaçamento abaixo do título
              }}>
                {bank ? 'Banco de questões de ' + bank.description : 'Bancos de Questões'}
              </div>
            </Box>
            <Box display="flex" sx={{flexGrow: 1}} justifyContent="flex-end">
              {!isSmallScreen &&
                  <Button
                      color="primary"
                      variant="contained"
                      onClick={handleClickOpenAddQuestion}
                      className={classesGeneral.buttons}
                      style={{height: '35px'}}>
                    <i className="fa-solid fa-plus" style={{marginRight:'10px', fontSize: '16px'}}></i>
                    {' Questões'}
                  </Button>}
                  <Dialog
                      open={openAddQuestion}
                      onClose={handleCloseAddQuestion}
                      fullWidth
                      maxWidth="md">
                    <DialogTitle>
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <div className={classesGeneral.subtitles} style={{ fontSize: 15 }}>
                          Lista de Questões
                        </div>
                        <IconButton
                            color="secondary"
                            onClick={handleCloseAddQuestion}
                            style={{ marginLeft: 'auto' }}
                            aria-label="fechar">
                          <CloseIcon />
                        </IconButton>

                      </Box>
                    </DialogTitle>
                    <DialogContent>
                          <QuestionList
                            onQuestionsSelected = {handleQuestionsSelected}
                            onClose={handleCloseAddQuestion}/>
                    </DialogContent>
                  </Dialog>
                  <DialogQuestione handleClose={handleCloseOpenConfirmAddQuestion}
                                   open={openConfirmAddQuestion}
                                   onClickAgree={onClickConfirmAddQuestions}
                                   onClickDisagree={onClickNoConfirmAddQuestions}
                                   mesage={'Deseja adicionar a(s) questão(ões) '+selectedQuestions+"?"}
                                   title={'Adicionar Questões a Avaliaçao'}/>
            </Box>
          </Box>

          {bank &&
              <SearchFilterQuestion
                enableSearchQuestionBank={false}
                onClickSearch={onClickSearch}
                idBank={bank.id}/>}
        </CardContent>
      </Card>
    </div>
  );
};

QuestionsBankToolbar.propTypes = {
  className: PropTypes.string,
  onChangeSearch: PropTypes.func,
  onClickSearch: PropTypes.func,
  searchText: PropTypes.string,
  history: PropTypes.object
};

export default withRouter(QuestionsBankToolbar);
